import React, { ReactNode } from 'react';

import { Container } from 'components/container/Container';

import s from './Footer.scss';
import { Highlight } from 'components/intro/Highlight';

interface FooterProps {
  logo: ReactNode;
}

export const Footer = ({ logo }: FooterProps) => (
  <div className={s.footer}>
    <Container>
      <div className={s.footer__content}>
        <a
          href="https://www.columbia.edu/"
          target="_blank"
          rel="noopener noreferrer"
          className={s.footer__logoLink}
        >
          {logo}
        </a>
        <a
          href="https://www.ericbalkanski.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={s.footer__authorLink}
        >
          <Highlight>Eric Balkanski</Highlight>
        </a>
      </div>
    </Container>
  </div>
);
