import React, { ReactNode } from 'react';

import s from './Highlight.scss';

interface HighlightProps {
  children: ReactNode;
}

export const Highlight = ({ children }: HighlightProps): any => {
  if (Array.isArray(children)) {
    children = children[0];
  }
  return (children as string)?.split(' ').map((word, index) => (
    <span key={word} className={s(s.highlight, { isFirst: index === 0 })}>
      <span className={s.highlight__text}>{word}</span>
      <span className={s.highlight__color} />
    </span>
  ));
};
