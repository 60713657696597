import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Logo from '../../assets/svg/columbia-logo.svg';

import { helmet } from '../../utils/helmet';
import { Header } from '../header/Header';
import { HeaderLink } from '../header/HeaderLink';
import { Footer } from '../footer/Footer';

import s from './AppLayout.scss';

interface AppLayoutProps {
  children: ReactNode;
}

// tslint:disable no-default-export
export default ({ children }: AppLayoutProps) => {
  const links = useStaticQuery(graphql`
    query LinksQuery {
      allMarkdownRemark(
        filter: { fields: { slug: { ne: "/" } } }
        sort: { fields: frontmatter___weight, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              label
            }
          }
        }
      }
    }
  `);

  return (
    <div className={s.layout}>
      <Helmet {...helmet} />

      <Header>
        {links.allMarkdownRemark.edges.map(({ node }) => (
          <HeaderLink name={node.frontmatter.label} key={node.fields.slug} to={node.fields.slug} />
        ))}
      </Header>

      {children}

      <Footer logo={<Logo />} />
    </div>
  );
};
