import favicon from 'assets/images/favicon.png';

const title = 'Machine Learning for Algorithm Design';

export const helmet = {
  title,
  titleTemplate: '%s - IEOR 8100',
  htmlAttributes: { lang: 'en' },
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, user-scalable=no',
    },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#000' },
    { name: 'msapplication-TileColor', content: '#000' },
    { name: 'theme-color', content: '#000' },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: favicon }],
};
